"use client";

import versionFile from "src/version.json";
import { parseCookies, setCookie } from "nookies";
import { COOKIES } from "@nilo/constants";
import { useState } from "react";

export const useVersion = () => {
    const [version] = useState<string>(versionFile?.version?.toString() || "");

    const saveCookie = (version) => {
        const maxAge = 30 * 24 * 60 * 60 * 60;
        setCookie(null, COOKIES.version, version, {
            maxAge,
            path: "/"
        });
    };

    const getCookie = () => {
        const cookies = parseCookies();
        return !!cookies[COOKIES.version] ? cookies[COOKIES.version] : "";
    }

    const hasNewVersion = () => {
        const cookieVersion = getCookie();
        if (version !== cookieVersion) {
            saveCookie(version);
            return true;
        }
        return false;
    }

    return {
        hasNewVersion,
        version
    }
}